import React from "react";
import { cn } from "../lib/helpers";

import { getGatsbyImageData } from "gatsby-source-sanity";
import clientConfig from "../../client-config";

import PortableText from "./portableText";
import { GatsbyImage } from "gatsby-plugin-image";
import BrushImage from "./brushImage";
import LetterText from "./letterText";
import FadeInSection from "./FadeIn";

import * as styles from "./aboutMain.module.css";
import * as commonStyles from "../styles/common.module.css";

function AboutMain({ aboutData, teamData = [] }) {
  const { images, _rawBodyBig, _rawBodySmall } = aboutData;

  const judit = teamData.find((member) => member._id === "6f1f56c7-df35-47e6-8171-a014436a5c7a");
  const team = teamData.filter((member) => member._id !== "6f1f56c7-df35-47e6-8171-a014436a5c7a");

  return (
    <div className={styles.container}>
      <FadeInSection>
        <h1
          className={cn(styles.headline, commonStyles.colorAccent, commonStyles.fontButlerMedium)}
        >
          O nás
        </h1>
        <div>
          <div className={cn(styles.textBig, commonStyles.fontButler, commonStyles.colorDarkGray)}>
            <PortableText blocks={_rawBodyBig} />
          </div>
          <div
            className={cn(styles.textSmall, commonStyles.fontButler, commonStyles.colorDarkGray)}
          >
            <PortableText blocks={_rawBodySmall} />
          </div>
        </div>
      </FadeInSection>
      <FadeInSection>
        <div className={styles.images}>
          {images.map((image, index) => {
            const imageData = getGatsbyImageData(image, {}, clientConfig.sanity);
            return (
              <div key={`image_${index}`}>
                <GatsbyImage image={imageData} alt={image.alt} />
              </div>
            );
          })}
        </div>
      </FadeInSection>
      <FadeInSection>
        <div className={styles.juditContainer}>
          <div className={styles.juditImage}>
            <BrushImage image={judit.image} />
          </div>
          <div className={styles.juditText}>
            <LetterText
              letter={judit.letter}
              blocks={judit._rawBio}
              title={judit.name}
              subtitle={judit.subtitle}
            />
          </div>
        </div>
      </FadeInSection>

      <div className={styles.wrapper}>
        {team.map((member, indexM) => (
          <div key={`member_${indexM}`} className={styles.box}>
            <FadeInSection>
              <LetterText
                letter={member.letter}
                blocks={member._rawBio}
                title={member.name}
                subtitle={member.subtitle}
                small
              />
            </FadeInSection>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AboutMain;
